
















































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'uOttawa1321Prelab4Q7',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswers: [],
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'The solvent dissolves the compound to be purified at low temperatures',
          value: 'highSolubilityLowTemp',
        },
        {
          text: 'The impurities are highly soluble in the solvent',
          value: 'impuritiesHighlySoluble',
        },
        {
          text: 'The solvent offers a large range of solubility for the target product as a function of temperature',
          value: 'largeSolubilityRangeTarget',
        },
        {
          text: 'The impurities are not soluble in the solvent',
          value: 'impuritiesInsoluble',
        },
        {
          text: 'The product must not react with the solvent',
          value: 'mustNotReact',
        },
        {
          text: 'The solvent offers a large range of solubility for the crude product as a function of temperature',
          value: 'largeSolubilityRangeCrude',
        },
      ],
      // é è
      optionsFr: [
        {
          text: 'Le solvant dissout le composé à être purifié à des temperatures inférieures',
          value: 'highSolubilityLowTemp',
        },
        {
          text: 'Les impuretés sont grandement solubles dans le solvant',
          value: 'impuritiesHighlySoluble',
        },
        {
          text: 'La solubilité du produit cible diffère beaucoup en fonction de la température du solvant',
          value: 'largeSolubilityRangeTarget',
        },
        {
          text: 'Les impuretés ne sont pas solubles dans le solvant',
          value: 'impuritiesInsoluble',
        },
        {
          text: 'Le produit ne doit pas réagir avec le solvant',
          value: 'mustNotReact',
        },
        {
          text: 'La solubilité du produit brute diffère beaucoup en fonction de la température du solvant',
          value: 'largeSolubilityRangeCrude',
        },
      ],
    };
  },
  computed: {
    image(): string {
      return '/img/assignments/organicVsWaterLayer2.png';
    },
  },
};
